import { ThemeProvider } from 'wikr-core-components';
import React, { ReactNode } from 'react';

import { getCustomProperties } from './helpers';

import GlobalStyle from './global-styles';
import themeConfig from '../../themeConfig';

interface ThemeProviderProps {
    children: ReactNode;
}

const { theme, inline } = themeConfig;

const Provider = ({ children }: ThemeProviderProps) => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyle rootVariables={getCustomProperties(inline)} />
                {children}
            </ThemeProvider>
        </>
    );
};

export default Provider;
