import { compareDates, getToday } from 'helpers/date';

import { ISubscription, ITrialUpcomingProduct } from 'types/subscription';
import { Product } from 'types/product';

const getUpcomingProduct = (subscription: ISubscription): Product | ITrialUpcomingProduct | null => {
    const { product, upcoming_product, is_trial, expired_date, subscription_period } = subscription;

    if (upcoming_product && compareDates(upcoming_product.started_from, getToday()) > 0) {
        return upcoming_product;
    }

    if (is_trial) {
        return {
            started_from: expired_date,
            amount: product?.amount,
            currency: product?.currency,
            subscription_period: subscription_period,
        };
    }

    return null;
};

export default getUpcomingProduct;
