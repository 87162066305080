export const USER_SOURCE = {
    WEB2APP: 'web2app',
};

export const DEFAULT_COUNTRY = 'US';

export const AVOID_ONLY_APP_LOGGED_IN_KEY = 'isAppLoggedIn';

export const GYM_LOCATION = 'gym';
export const HOME_LOCATION = 'home';
export const GA_USER_ID = '_ga';
