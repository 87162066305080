import { selectDiscountedSubscriptions } from 'store/subscriptions/selectors';
import { getAppropriateDiscountForSubscription } from 'store/subscriptions/helpers';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { PRODUCT_CODES, PRODUCT_TYPES } from 'constants/product';
import { DATE_FORMAT } from 'constants/date';
import { CURRENCY_SIGN_MAP } from 'constants/currency';
import { SECONDS_TO_SHOW_UPDATING_STATUS, SUBSCRIPTION_PAUSE_PERIOD, SUBSCRIPTION_STATES } from './constants';

import { useSelector } from 'hooks';

import { isEmpty, replaceUnderscoreToDash } from 'helpers/utils';
import { isMainSubscription } from 'helpers/subscriptions';
import { getPriceFromCents } from 'helpers/prices';
import { getFormattedDate, hasTimePassedSince } from 'helpers/date';
import { getSubscriptionName, getUpcomingProduct } from './helpers';

import VipSupportIcon from 'assets/img/svg/vip-support-ico.svg';
import LogoIcon from 'assets/images/logo.svg';

import { IDiscountsForSubscriptions } from 'types/subscription';
import { ISubscriptionCard } from './types';

import { trackSubscriptionCardCancelClick } from 'analytics/trackers/subscription';

const useSubscriptionCard = ({
    onCancelSubscription,
    onReturnOffer,
    onRestoreSubscription,
    onResumeSubscription,
    subscription,
}: ISubscriptionCard) => {
    const {
        product,
        state,
        is_trial: isTrial,
        expired_date: expiredDate,
        pause_to,
        pause_from,
        cancelled_at: cancelledAt,
        product_code: productCode,
        internal_cancel_at: internalCancelAt,
        contentId,
    } = subscription;

    const {
        amount,
        currency,
        trial_amount: trialAmount,
        subscription_period: subscriptionPeriod,
        subscription_trial_period: subscriptionTrialPeriod,
    } = product;

    const isCancelledSubscription = state === SUBSCRIPTION_STATES.CANCELLED || Boolean(cancelledAt);
    const isSubscriptionActive = state === SUBSCRIPTION_STATES.ACTIVE;
    const isSubscriptionRedemption = state === SUBSCRIPTION_STATES.REDEMPTION;
    const isSubscriptionPaused = state === SUBSCRIPTION_STATES.PAUSED;
    const isSubscriptionUpdating = state === SUBSCRIPTION_STATES.PENDING;

    const discountedSubscriptions: IDiscountsForSubscriptions = useSelector(selectDiscountedSubscriptions);

    const isDiscountOfferApplied = Boolean(subscription?.discount);
    const isReturnOfferApplied = Boolean(subscription?.restore_with_discount_time);
    const isRestoredSubscription = Boolean(subscription?.restore_time);

    const isExpiredSubscription = isCancelledSubscription && !isSubscriptionActive;
    const isExpiresSubscription = internalCancelAt && isSubscriptionActive;

    const isOfferAvailableForSubscription = !isEmpty(
        getAppropriateDiscountForSubscription(subscription, discountedSubscriptions, DISCOUNT_TYPES.extra)
    );

    const isDiscountedSubscription = isReturnOfferApplied || isDiscountOfferApplied;

    const expirationDate = getFormattedDate(expiredDate, DATE_FORMAT);
    const cancelledAtDate = getFormattedDate(cancelledAt, DATE_FORMAT);
    const pausedTo = pause_to ? getFormattedDate(pause_to, DATE_FORMAT) : '';

    const isTimePassedSincePaused = Boolean(
        pause_from && hasTimePassedSince(pause_from, 'seconds', SECONDS_TO_SHOW_UPDATING_STATUS)
    );
    const isReminderSet = subscription.reminder;
    const subscriptionPausePeriod = SUBSCRIPTION_PAUSE_PERIOD;
    const isVIPSupport = productCode === PRODUCT_CODES.APP_VIP_SUPPORT;
    const subscriptionType = isVIPSupport ? PRODUCT_TYPES.VIP_SUPPORT : PRODUCT_TYPES.PERSONALIZED_PROGRAM;
    const tabIcon = isVIPSupport ? VipSupportIcon : LogoIcon;
    const heading = replaceUnderscoreToDash(getSubscriptionName(productCode));
    const price = getPriceFromCents(isTrial ? trialAmount : amount, currency);
    const currencySign = CURRENCY_SIGN_MAP[currency] || currency;

    return {
        price: `${price} ${currencySign}`,
        state,
        tabIcon,
        heading,
        upcomingProduct: getUpcomingProduct(subscription),
        isSubscriptionRedemption,
        isExpiresSubscription,
        isExpiredSubscription,
        isSubscriptionPaused,
        isSubscriptionUpdating,
        isReturnOfferApplied,
        isDiscountedSubscription,
        isRestoredSubscription,
        isReminderSet,
        isTimePassedSincePaused,
        cancelledAtDate,
        pausedTo,
        handleCancelSubscription: () => {
            trackSubscriptionCardCancelClick(subscriptionType, productCode, contentId);
            onCancelSubscription(subscription);
        },
        isTrial,
        expirationDate: replaceUnderscoreToDash(cancelledAt ? cancelledAtDate : expirationDate),
        subscriptionPeriod: isTrial ? subscriptionTrialPeriod : subscriptionPeriod,
        isMainSubscription: isMainSubscription(productCode),
        productCode,
        subscriptionPausePeriod,
        handleResumeSubscription: () => {
            onResumeSubscription(subscription);
        },
        onShowReturnOffer: () => {
            onReturnOffer(subscription);
        },
        handleRestoreSubscription: () => {
            onRestoreSubscription(subscription);
        },
        isOfferAvailableForSubscription,
    };
};

export default useSubscriptionCard;
