import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import router from 'router';

import NotificationsContainer from 'containers/Notifications';

import NetworkErrorModal from './components/Modals/NetworkErrorModal';

import { store, persistor } from './store/configureStore';

import ThemeProvider from 'theme/provider';

import Sentry from './services/Sentry/SentryInstance';

Sentry.init();

function App() {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ThemeProvider>
                    <NetworkErrorModal />
                    <NotificationsContainer />
                    <RouterProvider router={router} />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
