import ApiClient from 'services/ApiClient';

import { GLOBAL_EMITTER_EVENTS, globalEventEmitter } from '../../../helpers/event-emmiter';

import {
    DeepLinkData,
    SignInData,
    UserInfoResponse,
    UserUpdateData,
    HashedEmailResp,
    GenerateTemporaryPasswordType,
    CheckTemporaryPasswordType,
    UserWorkoutInfo,
    UserGeoResp,
} from 'types/user/userApiInterface';

import apiActual from '../../../__actual/api';

// TODO MIGRATION LOGIC
class UserApi {
    getUser = async <Response = UserInfoResponse>(): Promise<Partial<Response>> => {
        const resData = await ApiClient.get<Response>('user/');

        // @ts-ignore
        if (resData.is_migrated) {
            globalEventEmitter.emit(GLOBAL_EMITTER_EVENTS.USER_IS_MIRGATED, {});
        }

        return resData;
    };

    getUserWorkoutInfoRequest = async <Response = UserWorkoutInfo>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>('user/first-workout-info');
    };

    update = async <Data = UserUpdateData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.patch<Data, Response>('user/', payload);
    };

    generateTemporaryPassword = async <Data = GenerateTemporaryPasswordType, Response = any>(
        payload: Data
    ): Promise<Partial<Response>> => {
        globalEventEmitter.clearLastEvent();

        try {
            return await ApiClient.post<Data, Response>('user/generate-temporary-password', payload);
        } catch (e: any) {
            if (e.response && e.response.status === 404 && e.response.data?.error === 'Not Found') {
                const resData = await apiActual.user.generateTemporaryPassword<Data, Response>(payload);
                globalEventEmitter.emit(GLOBAL_EMITTER_EVENTS.TEMPORARY_PASSWORD_WF_COMPLETED, {});
                return resData;
            }
            throw e;
        }
    };

    checkTemporaryPassword = async <Data = CheckTemporaryPasswordType, Response = any>(
        payload: Data
    ): Promise<Partial<Response>> => {
        if (globalEventEmitter.getLastEvent()?.event === GLOBAL_EMITTER_EVENTS.TEMPORARY_PASSWORD_WF_COMPLETED) {
            const resDataActual = await apiActual.user.checkTemporaryPassword<Data, Response>(payload);
            globalEventEmitter.emit(GLOBAL_EMITTER_EVENTS.AUTH_WF_COMPLETED, {});
            return resDataActual;
        } else {
            const resDataLegacy = await ApiClient.post<Data, Response>('user/check-temporary-password', payload);

            // @ts-ignore
            if (resDataLegacy.is_migrated) {
                globalEventEmitter.emit(GLOBAL_EMITTER_EVENTS.USER_IS_MIRGATED, {});
            }

            return resDataLegacy;
        }
    };

    signIn = async <Data = SignInData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('sign-in/', payload);
    };

    deepLinks = async <Response = DeepLinkData>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>('user/auth-deeplink');
    };

    getUserBySignature = async <Response = UserInfoResponse>(
        params: Record<string, string>
    ): Promise<Partial<Response> | unknown> => {
        try {
            const resDataLegacy = await ApiClient.get<Response>(`core/user/${params.uId}/${params.signature}`);

            // @ts-ignore
            if (resDataLegacy.is_migrated) {
                globalEventEmitter.emit(GLOBAL_EMITTER_EVENTS.USER_IS_MIRGATED, {});
            }

            return resDataLegacy;
        } catch (e: any) {
            if (e.response && e.response.status === 404) {
                const resDataActual = await apiActual.user.getUserBySignature<UserInfoResponse>(params);
                globalEventEmitter.emit(GLOBAL_EMITTER_EVENTS.AUTH_WF_COMPLETED, {});
                return resDataActual;
            }
            throw e;
        }
    };

    hashedEmail = async () => {
        return await ApiClient.get<HashedEmailResp>('front-hash');
    };

    getProducts = async () => {
        return await ApiClient.get<HashedEmailResp>('user/products');
    };

    getUserGeoData = async () => {
        return await ApiClient.get<UserGeoResp>('user/geo-data');
    };
}

export default UserApi;
