import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

import { resumeSubscription } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';

import { useDispatch } from 'hooks';

import { ISubscription } from 'types/subscription';

import {
    trackResumeFailedLoad,
    trackResumeRetryClick,
    trackResumeSuccessLoad,
    trackSubscriptionResumeClick,
    trackSuccessfulResumeContinue,
} from 'analytics/trackers/resumeSubscription';
import { useModals } from '../modals';

const useResumeSubscription = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openModal, closeModal } = useModals();

    const productRef = useRef<ISubscription | null>(null);

    const handleFailedResume = () => {
        openModal(ModalName.universalModal, {
            image: 'errorAttention',
            trackLoad: trackResumeFailedLoad,
            onClose: closeModal,
            onPrimaryClick: () => {
                closeModal();
                trackResumeRetryClick();
                initResumeSubscription(productRef.current as ISubscription);
            },
            withCloseButton: true,
            withContactUsLink: true,
            captions: {
                title: t('subscription.updating.issueModal.title'),
                primaryButton: t('basics.tryAgain'),
                tertiaryButton: t('basics.contactSupport'),
            },
            dataLocators: {
                modal: 'resumeSubModal',
                container: 'resumeSubscriptionPlanModal',
                primaryButton: 'errorModalTryAgain',
                closeButton: 'errorModalCloseButton',
            },
        });
    };

    const handleSuccessfulResume = () => {
        openModal(ModalName.restoreSubscriptionModal, {
            onClose: () => {
                closeModal();
            },
            onSubmit: () => {
                closeModal();
                trackSuccessfulResumeContinue();
            },
            trackLoad: trackResumeSuccessLoad,
            captions: {
                title: t('subscription.resume.success.title'),
                submitButton: t('basics.continue'),
            },
        });
    };

    const initResumeSubscription = (candidate: ISubscription) => {
        productRef.current = candidate;

        trackSubscriptionResumeClick();

        openModal(ModalName.waitingModal, {
            captions: {
                title: t('subscription.updatingPlan.inProgress'),
            },
        });
        dispatch(
            resumeSubscription({
                onSuccess: () => {
                    handleSuccessfulResume();
                },
                onError: () => {
                    handleFailedResume();
                },
            })
        );
    };

    return {
        initResumeSubscription,
    };
};

export default useResumeSubscription;
