import { Box, Button, Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Modal from 'components/Modal';
import ContactUsLink from 'components/ContactUsLink';

import attention from 'assets/img/webp/attention.webp';

import { ErrorModalProps } from './types';

import { ReminderErrorLoad } from 'analytics/trackers/reminderForSubscription';

const ErrorModal = ({ onClose, onSubmit }: ErrorModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            paddingX={16}
            paddingY={16}
            buttonWithBackground
            isOpen
            trackLoad={ReminderErrorLoad}
            onClose={onClose}
            withCloseButton
        >
            <Box paddingTop={56} paddingBottom={12}>
                <Image src={attention} alt="attention" center maxWidth={48} mb={12} />
                <Text text={t('basics.somethingWentWrong')} type="h5" center mb={24} />
                <Button text={t('basics.tryAgain')} onClick={onSubmit} mb={20} />
                <ContactUsLink boldText />
            </Box>
        </Modal>
    );
};

export default ErrorModal;
