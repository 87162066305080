import { Text, TextType } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { useGetCFLink } from 'hooks/common/useGetCFLink';

const ContactUsLink = ({
    onClick,
    dataLocator,
    text,
    type,
    boldText,
}: {
    onClick?: () => void;
    dataLocator?: string;
    text?: string;
    type?: TextType;
    boldText?: boolean;
}) => {
    const { t } = useTranslation();
    const link = useGetCFLink();

    return (
        <a href={link} target="_blank" rel="noreferrer" onClick={onClick} data-locator={dataLocator}>
            <Text type={type ?? 'overline'} center bold={boldText} text={text ?? t('basics.contactUs')} />
        </a>
    );
};

export default ContactUsLink;
