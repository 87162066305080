export const AVAILABLE_LOCALIZATIONS = ['de', 'en', 'es', 'fr', 'it', 'ja', 'pt'];
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_NAVIGATOR_LANGUAGE = 'en-US';
export const LANG_MAP: Record<string, string> = {
    pt: 'pt-PT',
};

export const LANG_TO_LOCALE_MAP: Record<string, string> = {
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    it: 'it-IT',
    ja: 'ja-JP',
    pt: 'pt-BR',
    de: 'de-DE',
};
