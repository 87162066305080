import { Box, Image, Text } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { viewPortWithoutHeader } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Container = styled.div`
    width: 100vw;
    min-height: ${viewPortWithoutHeader()};
    display: flex;
    flex-direction: row;
    align-items: start;

    ${mediaQueries.tablet} {
        align-items: center;
    }

    ${mediaQueries.tabletLandscape} {
        align-items: stretch;
        padding-bottom: 0;
    }
`;

export const AuthForm = styled.div`
    position: relative;
    width: ${({ theme }) => css`calc(400px + (${theme['default-indent']}px * 2))`};
    margin: 0 auto;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 343px;
    min-height: ${viewPortWithoutHeader()};

    ${mediaQueries.laptop} {
        padding-top: 0;
        justify-content: center;
    }
`;

export const AuthImage = styled(Box)`
    display: none;

    ${mediaQueries.tabletLandscape} {
        display: block;
    }
`;

export const LoginCouple = styled(Image)`
    height: ${viewPortWithoutHeader()};
`;

export const SupportContainer = styled.div`
    display: flex;
    margin-top: 20px;

    ${mediaQueries.laptop} {
        margin-top: 0;
        position: fixed;
        bottom: 27px;
    }
`;

export const HelpText = styled(Text)`
    margin-right: 5px;
`;
