import { DAYS_PER_YEAR } from 'constants/periods';
import { SUBSCRIPTION_STATES } from 'components/SubscriptionsTab/components/SubscriptionCard/constants';

import { isMainSubscription } from 'helpers/subscriptions';

import { ISubscription } from 'types/subscription';

import { SubscriptionsState } from './reducer';
import { RootReducer } from '../rootReducer';
import { DISCOUNT_TYPES } from 'constants/subscriptions';

export const selectSubscriptions = (state: RootReducer): SubscriptionsState => state.subscriptions;

export const selectDiscountedSubscriptions = (state: RootReducer) => state.subscriptions.discountedSubscriptions;

export const selectIsDiscountLoaded = (state: RootReducer) => state.subscriptions.isDiscountLoaded;

export const selectMainSubscription = (state: RootReducer) => {
    const subscriptions: ISubscription[] = selectSubscriptions(state).subscriptions;

    return subscriptions.find((subscription: ISubscription) => isMainSubscription(subscription.product_code));
};

export const selectIsMainSubscriptionActive = (state: RootReducer) => {
    const mainSubscription = selectMainSubscription(state);

    return mainSubscription?.state === SUBSCRIPTION_STATES.ACTIVE && !Boolean(mainSubscription?.cancelled_at);
};

export const selectIsReturnOfferApplied = (state: RootReducer) => {
    const mainSubscription = selectMainSubscription(state);

    return Boolean(mainSubscription?.discount.discount_type === DISCOUNT_TYPES.extra);
};

export const selectIsReminderSet = (state: RootReducer) => {
    const mainSubscription = selectMainSubscription(state);

    return Boolean(mainSubscription?.reminder);
};

export const selectIsOneYearMainSubscription = (state: RootReducer) => {
    const mainSubscription = selectMainSubscription(state);

    return mainSubscription?.subscription_period === DAYS_PER_YEAR;
};
