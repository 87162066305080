import { put, takeLatest, all, call } from 'redux-saga/effects';

import api from 'api';

import { handleErrorAction } from 'store/errors/actions';

import { setExercises, setLoadingState, fetchExercises, getDetails, saveDetails } from './actions';
import { notifyError } from '../notifications/actions';

import { FETCH_EXERCISES, GET_DETAILS } from './actionTypes';

import { createExercisesMap } from './helpers';

import { ExerciseDetails, ExerciseType } from 'types/exercise';

function* getExercises({ payload }: ReturnType<typeof fetchExercises>) {
    try {
        const { workoutId } = payload;

        yield put(setLoadingState(true));
        const response: ExerciseType[] = yield call(api.workouts.getExercisesByWorkout, workoutId);

        yield put(setExercises(createExercisesMap(response)));
        yield put(setLoadingState(false));
    } catch (error) {
        yield put(notifyError('basics.appError'));
        yield put(handleErrorAction(error, payload));
    }
}

function* getExerciseDetails({ payload }: ReturnType<typeof getDetails>) {
    try {
        const { exerciseId } = payload;

        const response: ExerciseDetails = yield call(api.workouts.getExercisesDetails, exerciseId);

        yield put(saveDetails(response));
    } catch (error) {
        yield put(notifyError('basics.appError'));
        yield put(handleErrorAction(error, payload));
    }
}

export default function* watchExercises() {
    yield all([takeLatest(FETCH_EXERCISES, getExercises), takeLatest(GET_DETAILS, getExerciseDetails)]);
}
