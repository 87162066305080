import { useEffect, useState } from 'react';

import { getCookie } from '__actual/helpers/cookie';

import { jwtDecode } from './jwt-decode';
import { GLOBAL_EMITTER_EVENTS, globalEventEmitter } from './event-emmiter';

type UseCheckAPI = {
    isWellfunnelApi: boolean;
};

type JwtPayload = {
    user_id: number | string;
};

export const useCheckAPI = (): UseCheckAPI => {
    const isWfByDefault = getCookie('USE_WF_MODE') === '1';

    const urlParams = new URLSearchParams(window.location.search);

    const token = urlParams.get('token') || getCookie('token');
    const decoded = (() => {
        try {
            return jwtDecode<JwtPayload>(token!);
        } catch {
            return {} as JwtPayload;
        }
    })();

    const [isWellfunnelApi, setIsWellfunnelApi] = useState(
        Boolean(isWfByDefault || (decoded?.user_id && typeof decoded.user_id === 'string'))
    );

    useEffect(() => {
        if (isWfByDefault) return;

        globalEventEmitter.on(GLOBAL_EMITTER_EVENTS.TEMPORARY_PASSWORD_WF_COMPLETED, () => {
            // Used to understand that user logging switched to the 2nd step, using WF API
        });

        globalEventEmitter.on(GLOBAL_EMITTER_EVENTS.USER_IS_MIRGATED, () => {
            // The old API said that user has the is_mirgated flag, we're switching to the WF API,
            // but still working with the OLD token
            setIsWellfunnelApi(true);
        });

        globalEventEmitter.on<any>(GLOBAL_EMITTER_EVENTS.AUTH_WF_COMPLETED, () => {
            // The user logging is completed on the 2nd step, we are refreshed to update
            // user session with the WF token
            location.reload();
        });
    }, [isWfByDefault]);

    return {
        isWellfunnelApi,
    };
};
