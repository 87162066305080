import { DISCOUNT_TYPES } from 'constants/subscriptions';

import ApiClient from 'services/ApiClient';

import {
    ICancelSubscriptionRequest,
    IChangeSubscription,
    IDiscountSubscription,
    IPauseSubscription,
    IReminderOffer,
    IRestoreSubscription,
    IResumeSubscription,
    IReturnSubscription,
    ISubscription,
    ISubscriptionFeedback,
} from 'types/subscription';
import { IMessageResponse, IResultResponse } from '../types/response';

class SubscriptionsApi {
    getSubscriptions = async <Response = ISubscription>(): Promise<Partial<Response> | unknown> => {
        return await ApiClient.get<Response>('user/subscriptions');
    };

    unsubscribe = async <Data = ICancelSubscriptionRequest, Response = IResultResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<Data, Response>('solidgate/subscription/cancel', payload);
    };

    sendFeedback = async <Data = ISubscriptionFeedback, Response = IMessageResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post('feedback/', payload);
    };

    changeSubscription = async <Data = IChangeSubscription, Response = IResultResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post('solidgate/subscription/change/', payload);
    };

    getDiscountSubscriptions = async <Response = IDiscountSubscription>(payload: {
        subscription_external_id: string;
        subscription_discount_type: DISCOUNT_TYPES;
    }): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<typeof payload, Response>('solidgate/subscription/discount', payload);
    };

    restoreSubscription = async <Data = IRestoreSubscription, Response = IResultResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post('solidgate/subscription/restore/', payload);
    };

    returnSubscription = async <Response = string>(
        payload: IReturnSubscription
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post('solidgate/subscription/restore-switch/', payload);
    };

    pauseSubscription = async <Response = string>(
        payload: IPauseSubscription
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post('solidgate/subscription/pause', payload);
    };

    resumeSubscription = async <Response = string>(
        payload: IResumeSubscription
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post('solidgate/subscription/resume', payload);
    };

    setReminder = async <Response = string>(payload: IReminderOffer): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post('subscription/reminder', payload);
    };
}

export default SubscriptionsApi;
