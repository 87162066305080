import config from 'appConfig';

import { PRIVATE } from 'router/routes';

import { getLanguage } from 'api/utils/languageManagment';

import { SidebarEventLabels } from 'analytics/constants/labels';

import { useGetCFLink } from 'hooks/common/useGetCFLink';

import { getFaqLinks } from 'helpers/getFAQLinks';
import { getDeviceOs } from 'helpers/getDeviceOs';

import { Guides, Settings, DownloadApp, Workout } from 'components/Icon';

import { paidLinksCollectionType, primaryLinkType } from './types';

const lang = getLanguage();
const deviceOs = getDeviceOs();

export const getPaidOnlyLinks = (): paidLinksCollectionType[] => [
    {
        link: {
            to: PRIVATE.WORKOUT_BUILDER.path,
            icon: Workout,
            title: 'sidebar.workout',
            dataLocator: 'workouts',
            analyticLabel: SidebarEventLabels.workout,
        },
        positionIndex: 1,
    },
];

export const getFreeLinks = (mainPath: string): primaryLinkType[] => [
    {
        to: mainPath,
        icon: DownloadApp,
        title: 'sidebar.app',
        dataLocator: 'burgerMuscleBoosterApp',
        analyticLabel: SidebarEventLabels.app,
    },
    {
        to: PRIVATE.FITNESS_GUIDES.path,
        icon: Guides,
        title: 'sidebar.guides',
        dataLocator: 'burgerGuides',
        analyticLabel: SidebarEventLabels.guides,
    },
    {
        to: PRIVATE.ACCOUNT_DEFAULT.path,
        icon: Settings,
        title: 'sidebar.settings',
        dataLocator: 'burgerAccountSettings',
        analyticLabel: SidebarEventLabels.account,
    },
];

export const useGetSecondaryLinks = () => {
    const cfLink = useGetCFLink();

    return [
        {
            to: getFaqLinks(lang, String(deviceOs)),
            title: 'sidebar.faq',
            dataLocator: 'burgerFAQ',
            target: '_blank',
            analyticLabel: SidebarEventLabels.faq,
        },
        {
            to: cfLink,
            title: 'basics.contactUs',
            dataLocator: 'contactUsLink',
            target: '_blank',
        },
        {
            to: `${config.links.termsOfUse}?language=${lang}`,
            title: 'sidebar.terms',
            dataLocator: 'burgerTermsOfUse',
            target: '_blank',
            analyticLabel: SidebarEventLabels.terms,
        },
        {
            to: `${config.links.privacyPolicy}?language=${lang}`,
            title: 'sidebar.privacy',
            dataLocator: 'burgerPrivacyPolicy',
            target: '_blank',
            analyticLabel: SidebarEventLabels.privacy,
        },
        {
            to: `${config.links.refundPolicy}?language=${lang}`,
            title: 'sidebar.refund',
            dataLocator: 'burgerRefundPolicy',
            target: '_blank',
            analyticLabel: SidebarEventLabels.refund,
        },
    ];
};

export const SIDEBAR_WIDTH = 312;
