/* eslint-disable max-lines */
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import config from 'config';

import { PRIVATE, PUBLIC } from 'router/routes';
import isPrivateRoute from 'router/helpers/isPrivateRoute';

import { getToken, removeToken, setToken } from 'api/utils/tokenManagement';
import api from 'api';

import { getUserRegionSaga } from 'store/user/sagas';
import { resetUserData, setUserData } from 'store/user/actions';
import { resetSubscriptions } from 'store/subscriptions/actions';
import { notifyError } from 'store/notifications/actions';
import { navigateTo } from 'store/navigation/actions';
import { handleErrorAction } from 'store/errors/actions';
import { getDeepLink } from 'store/deepLink/sagas';
import { handleLoginInstructionFeature, initAbTesting } from 'store/abTesting/sagas';

import {
    authenticationBySignature,
    authenticationFailed,
    authenticationSuccess,
    checkTemporaryPasswordRequest,
    generateTemporaryPasswordRequest,
    setAuthenticationStatus,
    setAuthRedirectUrl,
    signInError,
    signInRequest,
} from './actions';

import {
    AUTHENTICATE,
    AUTHENTICATE_BY_SIGNATURE,
    CHECK_TEMPORARY_PASSWORD_REQUEST,
    GENERATE_TEMPORARY_PASSWORD_REQUEST,
    LOG_OUT,
    SIGN_IN_REQUEST,
} from './actionTypes';

import { GA_USER_ID } from 'constants/user';
import { HIDE_FRONT_CHAT_VALUE } from 'constants/frontChat';

import Sentry from 'services/Sentry/SentryInstance';
import { initFrontChat, shutdownFrontChat } from 'services/FrontChat';
import { clearUserDataFromAnalytics, setUserDataToAnalytics } from 'services/Analytics';

import { getSubscriptionsPageUrl } from 'helpers/pathes';
import { getCookie } from 'helpers/cookie';

import { UserStore } from 'types/store/userStore';

import { authRedirectURLSelector } from './selectors';

function* generateTemporaryPassword({ payload }: ReturnType<typeof generateTemporaryPasswordRequest>) {
    const { email, onError, onSuccess } = payload;

    try {
        yield call(api.user.generateTemporaryPassword, payload);

        onSuccess && onSuccess();
    } catch (error) {
        yield put(notifyError('basics.appError'));
        yield put(handleErrorAction(error, { email }));

        onError && onError();
    }
}

function* checkTemporaryPassword({ payload }: ReturnType<typeof checkTemporaryPasswordRequest>) {
    const { email, password, onError, onSuccess } = payload;

    const gaUserId = getCookie(GA_USER_ID);

    try {
        const userDataResponse: UserStore = yield call(api.user.checkTemporaryPassword, {
            email,
            password,
            ...(gaUserId && { google_analytics_id: gaUserId }),
        });

        yield put(setAuthRedirectUrl(PRIVATE.MAIN.path));
        yield call(authorise, { userData: userDataResponse, authBeforeRedirect: true });

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(notifyError('basics.appError'));
        yield put(handleErrorAction(error, payload));

        onError && onError();
    }
}

function* signIn({ payload }: ReturnType<typeof signInRequest>) {
    const { userData, onError } = payload;

    try {
        const userDataResponse: UserStore = yield call(api.user.signIn, userData);

        yield put(setAuthRedirectUrl(PRIVATE.MAIN.path));

        yield call(authorise, { userData: userDataResponse, authBeforeRedirect: true });
    } catch (error: any) {
        yield put(notifyError('login.error'));
        yield put(handleErrorAction(error, payload));

        if (error?.error) yield put(signInError(error.error));

        onError && onError();
    }
}

function* authenticate() {
    const pathname = window.location?.pathname;

    const isPrivate = isPrivateRoute(pathname);

    yield put(setAuthRedirectUrl(isPrivate ? pathname : PRIVATE.MAIN.path));

    const token = getToken();

    if (!token) {
        yield put(setAuthenticationStatus(false));

        return;
    }

    try {
        const userData: UserStore = yield call(api.user.getUser);

        yield call(authorise, { userData });
    } catch (error: any) {
        removeToken();

        yield put(notifyError('basics.appError'));

        yield put(authenticationFailed());
        yield put(handleErrorAction(error));

        yield put(navigateTo({ url: PUBLIC.TEMPORARY_PASSWORD.path }));
    }
}

function* authenticateBySignature({ payload }: ReturnType<typeof authenticationBySignature>) {
    const { uId, signature } = payload;

    yield put(setAuthRedirectUrl(getSubscriptionsPageUrl()));

    try {
        const userData: UserStore = yield call(api.user.getUserBySignature, { uId, signature });

        yield call(authorise, { userData });
    } catch (error: any) {
        removeToken();

        yield put(notifyError('basics.appError'));
        yield put(authenticationFailed());
        yield put(handleErrorAction(error, payload));

        yield put(navigateTo({ url: PUBLIC.TEMPORARY_PASSWORD.path }));
    }
}

function* authorise({ userData, authBeforeRedirect }: { userData: UserStore; authBeforeRedirect?: boolean }) {
    userData?.token && setToken(userData.token);

    Sentry.setUser({
        email: userData.email as string,
        user_id: userData.user_id as number,
    });

    setUserDataToAnalytics({
        id: Number(userData.id),
        country: String(userData.country),
        gender: userData.gender,
        abTestName: userData.ab_test_name,
        region: userData.geo_region_code,
    });

    yield call(initAbTesting, userData);
    yield call(handleLoginInstructionFeature, userData.is_logged_in);

    if (authBeforeRedirect) {
        yield put(setAuthenticationStatus(true));
    }

    const redirectURL: string = yield select(authRedirectURLSelector);

    yield put(navigateTo({ url: redirectURL || PRIVATE.MAIN.path }));

    yield put(setUserData(userData));

    yield all([call(getUserRegionSaga), call(getDeepLink)]);

    yield delay(300);

    yield put(authenticationSuccess());

    const { name, email, language } = userData;

    if (config.HIDE_FRONT_CHAT !== HIDE_FRONT_CHAT_VALUE) {
        initFrontChat({ name, email: String(email), language: String(language) });
    }

    yield put(setAuthRedirectUrl(null));
}

function* logout() {
    clearUserDataFromAnalytics();

    removeToken();

    Sentry.clearUser();

    yield put(setAuthenticationStatus(false));
    yield put(resetUserData());
    yield put(resetSubscriptions());

    yield put(navigateTo({ url: PUBLIC.TEMPORARY_PASSWORD.path }));

    shutdownFrontChat();
}

export default function* watchAuth() {
    yield all([
        takeLatest(LOG_OUT, logout),
        takeLatest(SIGN_IN_REQUEST, signIn),
        takeLatest(AUTHENTICATE, authenticate),
        takeLatest(AUTHENTICATE_BY_SIGNATURE, authenticateBySignature),
        takeLatest(GENERATE_TEMPORARY_PASSWORD_REQUEST, generateTemporaryPassword),
        takeLatest(CHECK_TEMPORARY_PASSWORD_REQUEST, checkTemporaryPassword),
    ]);
}
