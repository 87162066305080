import * as action from './actions';

import * as actionTypes from './actionTypes';

import { UserStore } from 'types/store/userStore';
import { InferValueTypes } from 'types/commonTypes';

const initialState: UserStore = {
    id: null,
    country: null,
    date_of_birth: null,
    email: null,
    fitness_level: null,
    gender: '',
    goal: null,
    height: null,
    is_paid: false,
    is_password: false,
    surname: '',
    language: null,
    localization: null,
    name: '',
    product_list: null,
    target_bodytype: null,
    target_weight: null,
    target_zone: null,
    units: null,
    user_id: null,
    weight: null,
    token: null,
    ab_test_name: '',
    is_logged_in: false,
    payment_postal_code: null,
    geo_postal_code: null,
    geo_region_code: null,
    geo_region_name: null,
    user_postal_code: null,
    training_locations: null,
    user_workout_info: {
        start_first_workout: false,
        finish_first_workout: false,
    },
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const userReducer = (state = initialState, action: ActionType): UserStore => {
    switch (action.type) {
        case actionTypes.RESET_USER_DATA:
            return { ...initialState };

        case actionTypes.UPDATE_USER_SUCCESS:

        case actionTypes.SET_USER_DATA:
            return { ...state, ...action.payload };

        case actionTypes.SET_USER_WORKOUT_INFO:
            return { ...state, user_workout_info: action.payload };

        case actionTypes.UPDATE_PRODUCT_CODES:
            return { ...state, product_list: state.product_list && { ...state.product_list, ...action.payload } };

        case actionTypes.SET_USER_REGION:
            return {
                ...state,
                geo_region_code: action.payload,
            };

        default:
            return state;
    }
};

export default userReducer;
