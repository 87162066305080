import config from 'config';

import { setToken, getToken } from 'api/utils/tokenManagement';

import { logOutOperation } from 'store/auth/operations';

import { ERROR_LEVELS, SENTRY_AXIOS } from 'services/Sentry/constants';

import sentry from 'services/Sentry/SentryInstance';

import { ClientError, initApiClient } from 'welltech-web-api-client';

const MAX_RETRIES = 3;

function ApiClient() {
    return initApiClient({
        //@ts-ignore
        apiUrl: config.WF_API_URL,
        apiVersion: config.API_VERSION,
        //@ts-ignore
        apiKey: config.WF_API_KEY,
        retries: MAX_RETRIES,
        timeout: 35000,
        requestInterceptors: [
            (config) => {
                config.headers['project-id'] = 'menscoach';

                return config;
            },
        ],
        getAuthToken: () => getToken(),
        onUpdateToken: (token) => {
            setToken(token);
        },
        onAuthorisationError: (error) => {
            const typedError = error as ClientError;

            const errorStatus = typedError?.response?.status;

            if (errorStatus === 403) {
                logOutOperation();
            }
        },
        onErrorHandler: ({ extras, status, message, error }) => {
            const level = Number(status) >= 500 ? ERROR_LEVELS.CRITICAL : ERROR_LEVELS.ERROR;

            // @ts-ignore
            sentry.logError(new Error(message), SENTRY_AXIOS, level, { response: error?.response, ...extras });
        },
    });
}

export default ApiClient();
