export interface UserData {
    date_of_birth: string;
    email: string;
    gender: string;
    height: number;
    weight: number;
    target_weight: number;
}

export interface UserUpdateData extends Partial<UserData> {
    country?: string;
    surname?: string;
    units?: string;
    sendpulse_mailing_active?: boolean;
    password?: string;
    phone?: string;
    name?: string;
}

export type GenerateTemporaryPasswordType = {
    email: string;
};

export type CheckTemporaryPasswordType = {
    email: string;
    password: string;
};

export interface UserInfoResponse extends UserData {
    user_id: number;
    token: string;
    name: null | string;
    surename: null | string;
    is_paid: false;
    is_migrated: boolean;
    language: string;
    country: null | string;
    product_list: null | {
        [product: string]: boolean;
    };
    units?: string;
    localization: string;
    target_zone: string[];
    test_name?: string;
    ip_address: string;
    fitness_level: string;
    phone?: string;
}

export interface SignInData {
    password: string;
    email: string;
}

export interface DeepLinkData {
    url: string;
}

export interface HashedEmailResp {
    data: string;
}

export interface UserGeoResp {
    geo_postal_code: string;
    geo_region_code: string;
    geo_region_name: string;
}

export enum UnitTypes {
    Imperial = 'imperial',
    Metric = 'metric',
}

export interface UserWorkoutInfo {
    finish_first_workout: boolean;
    start_first_workout: boolean;
}
