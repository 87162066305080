import { call, put, select } from 'redux-saga/effects';

import { PRIVATE } from 'router/routes';

import { getLanguage } from 'api/utils/languageManagment';

import { selectCurrentUser } from 'store/user/selectors';
import { notifyError } from 'store/notifications/actions';
import { setAuthRedirectUrl } from 'store/auth/actions';

import { setFeatures } from './actions';

import { START_FINISH_TEST } from 'constants/features';

import { growthBook } from 'services/GrowthBook/GrowthBookInstance';

import { getDeviceType } from 'helpers/getDeviceType';
import { getDeviceOs } from 'helpers/getDeviceOs';
import { getDeviceBrowser } from 'helpers/getDeviceBrowser';
import { getAgeFromBirthday } from 'helpers/date';
import { isFeatureEnabled } from './helpers';

import { UserStore } from 'types/store/userStore';
import { FeatureDefinition } from '@growthbook/growthbook/src/types/growthbook';

import { getUserWorkoutInfoSaga } from '../user/sagas';

export function* initAbTesting(userData: UserStore) {
    try {
        const { user_id, country, date_of_birth, is_paid } = userData;

        yield growthBook.loadFeatures({ autoRefresh: true });

        growthBook.setAttributes({
            user_id: user_id,
            loggedIn: true,
            country,
            browser: getDeviceBrowser(),
            url: window.location.href,
            device_type: getDeviceType(),
            os_name: getDeviceOs(),
            language: getLanguage(),
            gender: 'male',
            age: getAgeFromBirthday(date_of_birth),
            is_paid,
        });

        const features: Record<string, FeatureDefinition> = growthBook.getFeatures();

        yield put(setFeatures(features));
    } catch (error: any) {
        console.error(`AB test init error: ${error}`);
        yield put(notifyError('growthBook.error'));
    }
}

export function* handleLoginInstructionFeature(is_logged_in: boolean) {
    const isStartWorkoutFeatureEnabled = isFeatureEnabled(START_FINISH_TEST);

    if (isStartWorkoutFeatureEnabled && is_logged_in) {
        yield call(getUserWorkoutInfoSaga);

        const { user_workout_info } = yield select(selectCurrentUser);
        const finishFirstWorkout = user_workout_info?.finish_first_workout;
        const startFirstWorkout = user_workout_info?.start_first_workout;

        if (!startFirstWorkout && !finishFirstWorkout) {
            yield put(setAuthRedirectUrl(PRIVATE.START_WORKOUT_PAGE.path));
        }

        if (startFirstWorkout && !finishFirstWorkout) {
            yield put(setAuthRedirectUrl(PRIVATE.FINISH_WORKOUT_PAGE.path));
        }

        if (finishFirstWorkout) {
            yield put(setAuthRedirectUrl(PRIVATE.CONTINUE_PROGRESS_PAGE.path));
        }
    }
}
