export enum GLOBAL_EMITTER_EVENTS {
    TEMPORARY_PASSWORD_WF_COMPLETED,
    AUTH_WF_COMPLETED,
    USER_IS_MIRGATED,
}

type Listener<T = any> = (payload: T) => void;

class EventEmitter {
    private events: { [key: string]: Listener[] } = {};
    private lastEvent: { event: GLOBAL_EMITTER_EVENTS; payload: any } | null = null; // Зберігаємо останню подію

    on<T>(event: GLOBAL_EMITTER_EVENTS, listener: Listener<T>): void {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(listener);
    }

    off<T>(event: GLOBAL_EMITTER_EVENTS, listenerToRemove: Listener<T>): void {
        if (!this.events[event]) return;

        this.events[event] = this.events[event].filter((listener) => listener !== listenerToRemove);
    }

    emit<T>(event: GLOBAL_EMITTER_EVENTS, payload: T): void {
        if (!this.events[event]) {
            this.events[event] = [];
        }

        // Зберігаємо останню подію і її дані
        this.lastEvent = { event, payload };

        // Викликаємо слухачі для цієї події
        this.events[event].forEach((listener) => listener(payload));
    }

    clearLastEvent(): void {
        this.lastEvent = null;
    }

    getLastEvent<T>(): { event: GLOBAL_EMITTER_EVENTS; payload: T } | null {
        return this.lastEvent;
    }
}

export const globalEventEmitter = new EventEmitter();
