import { createRoot } from 'react-dom/client';
import { FC } from 'react';

import config from 'config';

import { useCheckAPI } from 'helpers/useCheckAPI';

import LagecyApp from './__legacy/App';
import ActualApp from './__actual/App';

// AQA needs
window.HIDE_FRONT_CHAT = Number(config.HIDE_FRONT_CHAT);

const root = createRoot(document.getElementById('root') as HTMLElement);

const ProjectSwitcher: FC = () => {
    const { isWellfunnelApi } = useCheckAPI();

    // TODO add suspense on import
    return isWellfunnelApi ? <ActualApp /> : <LagecyApp />;
};

root.render(<ProjectSwitcher />);
