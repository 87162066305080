import { measure } from 'constants/units';

import { nameInput, weightInput } from './types';

const { TALL_UNIT } = measure;

export const getWeightFields = (t: (key: string) => string): weightInput[] => {
    return [
        {
            field: 'heightImperialFt',
            unit: TALL_UNIT.imperial.ft,
            min: 4,
            max: 7,
            label: t('account.profile.height'),
            placeholder: t('account.profile.height'),
            dataLocator: 'heightFt',
        },
        {
            field: 'heightImperialIn',
            unit: TALL_UNIT.imperial.in,
            min: 0,
            max: 11,
            label: t('account.profile.height'),
            placeholder: t('account.profile.height'),
            dataLocator: 'heightIn',
        },
    ];
};

export const getNameFields = (t: (key: string) => string): nameInput[] => [
    {
        name: 'name',
        label: t('account.profile.firstName'),
        dataLocator: 'firstName',
        placeholder: t('account.profile.firstName.placeholder'),
    },
    {
        name: 'last_name',
        label: t('account.profile.lastName'),
        dataLocator: 'lastName',
        placeholder: t('account.profile.lastName.placeholder'),
    },
];
