import {
    BIGGEST_WINDOW_WIDTH,
    LARGE_WINDOW_WIDTH,
    MEDIUM_WINDOW_WIDTH,
    MOBILE_WINDOW_WIDTH,
    TABLET_WINDOW_WIDTH,
} from 'theme/constants';

import { isWindowMatchSize } from 'helpers/utils';

export const isBiggestDesktopDevice = isWindowMatchSize(BIGGEST_WINDOW_WIDTH);
export const isLargeDesktopDevice = isWindowMatchSize(LARGE_WINDOW_WIDTH);
export const isDesktopDevice = isWindowMatchSize(MEDIUM_WINDOW_WIDTH);
export const isTabletDevice = isWindowMatchSize(TABLET_WINDOW_WIDTH);
export const isMobileDevice = isWindowMatchSize(MOBILE_WINDOW_WIDTH);
