import { useEffect } from 'react';

import { selectDiscountedSubscriptions } from 'store/subscriptions/selectors';
import { checkBeforeFetchingDiscount, getAppropriateDiscountForSubscription } from 'store/subscriptions/helpers';
import { fetchDiscountSubscriptions } from 'store/subscriptions/actions';
import { isUserFromCRM } from 'store/auth/selectors';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { SUBSCRIPTION_STATES } from 'components/SubscriptionsTab/components/SubscriptionCard/constants';

import { useDispatch, useSelector } from 'hooks';

import { IDiscountsForSubscriptions, ISubscription } from 'types/subscription';

const useDiscountProduct = (subscriptions: ISubscription[]) => {
    const dispatch = useDispatch();

    const discountedSubscriptions: IDiscountsForSubscriptions = useSelector(selectDiscountedSubscriptions);

    const isFromCRM: boolean = useSelector(isUserFromCRM);

    const subscriptionsAvailableForOffer: ISubscription[] = subscriptions.filter((subscription: ISubscription) => {
        const isCancelled = subscription.state === SUBSCRIPTION_STATES.CANCELLED;
        const isInternalCancellation =
            // TODO check this
            // !subscription.restore_with_discount_time &&
            subscription.internal_cancel_at;
        const allowedForDiscount = checkBeforeFetchingDiscount(subscription);

        // if subscription is cancelled by cancel now (in Solid) we should NOT try to get discount -> !isFromCRM && subscription.state !== SUBSCRIPTION_STATES.CANCELLED
        // if user came from CRM we should try to get discount -> for expired AND expired soon (active but have internal_cancel_at field) subscription.state
        return (
            allowedForDiscount &&
            ((isInternalCancellation && !isFromCRM && !isCancelled) ||
                (isCancelled && isFromCRM) ||
                (isInternalCancellation && isFromCRM && isCancelled) ||
                (subscription.state === SUBSCRIPTION_STATES.ACTIVE && isInternalCancellation && isFromCRM))
        );
    });

    useEffect(() => {
        subscriptionsAvailableForOffer.forEach((subscription: ISubscription) => {
            const alreadyExistedDiscount = getAppropriateDiscountForSubscription(
                subscription,
                discountedSubscriptions,
                DISCOUNT_TYPES.extra
            );

            if (!alreadyExistedDiscount) {
                dispatch(
                    fetchDiscountSubscriptions({
                        discount_type: DISCOUNT_TYPES.extra,
                        external_id: subscription?.external_id as string,
                        subscriptionsAvailableForOffer,
                    })
                );
            }
        });
    }, [subscriptionsAvailableForOffer.length, discountedSubscriptions]);
};

export default useDiscountProduct;
