import { uaParser } from 'wikr-core-analytics';

export const isEmpty = (value: null | undefined | string | number | object): boolean => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0) ||
        (typeof value === 'number' && Number(value) === Infinity)
    );
};

export const getDeviceInfo = () => {
    const UAParser = new uaParser();
    const userAgent = window.navigator.userAgent;
    const userAgentParsed = UAParser.setUA(userAgent);

    const os = userAgentParsed.getOS();
    const browser = userAgentParsed.getBrowser();

    return {
        os: `${os.name} ${os.version}`,
        browser: `${browser.name} ${browser.version}`,
    };
};

export const replaceUnderscoreToDash = (string: string): string => string.replace(/_/g, '-');

export const isWindowMatchSize = (width: number) => {
    const mediaQuery = window.matchMedia(`(min-width: ${width}px)`);

    return mediaQuery.matches;
};
