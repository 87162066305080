/* eslint-disable max-lines */
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';

import { PRIVATE } from 'router/routes';

import { selectIsUserFromCalifornia } from 'store/user/selectors';
import { selectDiscountedSubscriptions } from 'store/subscriptions/selectors';
import { getAppropriateDiscountForSubscription } from 'store/subscriptions/helpers';
import { returnSubscription } from 'store/subscriptions/actions';
import { ModalName } from 'store/modals/types';
import { isUserFromCRM } from 'store/auth/selectors';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { ACCOUNT_SUBSCRIPTION, RETURN_OFFER, RETURN_OFFER_CA, RETURN_OFFER_DISCOUNT } from 'analytics/constants/ids';

import { useDispatch, useSelector } from 'hooks';

import { isEmpty } from 'helpers/utils';
import { isMainSubscription } from 'helpers/subscriptions';
import { getCurrencySign, getDiscount, getPriceForCurrency, getPriceFromCents } from 'helpers/prices';
import { checkIsProductIdWithTestPrice } from './helpers';

import { IUserFeedback } from 'components/Modals/CancellationReasonModal/types';

import {
    IDiscountsForSubscriptions,
    IDiscountSubscription,
    IDiscountSubscriptionInfo,
    ISubscription,
} from 'types/subscription';
import { Product } from 'types/product';

import {
    trackCancelSubscriptionFailedRetryClick,
    trackCancelSubscriptionFailedScreenLoad,
    trackCloseDiscountOffer,
    trackCloseSurvey,
    trackDiscardCancellation,
    trackRenewPlan,
    trackSurveyLoad,
} from 'analytics/trackers/subscription';
import { trackShowReturnOfferForCrmClick } from 'analytics/trackers/returnOffer';
import {
    trackDiscountApplied,
    trackDiscountAppliedClick,
    trackFailedCancelOffer,
    trackOfferErrorModalLoad,
    trackRetryOfferClick,
    trackReturnOfferLoad,
    trackSuccessChangeProduct,
} from 'analytics/trackers/changeProduct';
import { useModals } from '../modals';

const useCommonSubscriptionLogic = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { openModal, closeModal } = useModals();

    const specialOfferProductRef = useRef<IDiscountsForSubscriptions>({});

    const discountedSubscriptions: IDiscountsForSubscriptions = useSelector(selectDiscountedSubscriptions);
    const isCaliforniaFlow = useSelector(selectIsUserFromCalifornia);
    const isFromCRM: boolean = useSelector(isUserFromCRM);

    useEffect(() => {
        specialOfferProductRef.current = discountedSubscriptions;
    }, [discountedSubscriptions]);

    const getLabel = () => {
        return isCaliforniaFlow ? RETURN_OFFER_CA : RETURN_OFFER_DISCOUNT;
    };

    const displaySurveyModal = (onSubmit: ({ reason }: IUserFeedback) => void, flow: string) => {
        openModal(ModalName.cancellationReasonModal, {
            onClose: () => {
                trackCloseSurvey();
                closeModal();
            },
            onDiscard: () => {
                trackDiscardCancellation();
                closeModal();
            },
            onSubmit: (reason: IUserFeedback) => {
                onSubmit(reason);
            },
            trackLoad: trackSurveyLoad,
            captions: {
                headerTitle: isCaliforniaFlow ? t('basics.feedback') : t('subscription.text.cancel'),
                ...(flow === RETURN_OFFER && {
                    additionalInfo: {
                        title: t('basics.subscription.cancelledTitle'),
                        text: t('subscription.cancellation.returnOffer.statusAdditionalInfo', {
                            minutesAmount: 15,
                        }),
                    },
                }),
                title: t('subscription.cancellationReason.title.mainReason'),
                submitButton: t('basics.continue'),
                ...(!isCaliforniaFlow && {
                    discardButton: t('subscription.cancelDialog.discard'),
                }),
            },
        });
    };

    const handleSubmitReturnOffer = (discountedSubscriptions: IDiscountSubscription, candidate: ISubscription) => {
        displayUpdatingProcessModal();

        dispatch(
            returnSubscription({
                external_id: candidate?.external_id as string,
                product: discountedSubscriptions,
                onError: () => {
                    isCaliforniaFlow && trackFailedCancelOffer();
                    displayProcessingIssue(
                        () => trackOfferErrorModalLoad(RETURN_OFFER, getLabel(), candidate.contentId),
                        getLabel(),
                        () => handleSubmitReturnOffer(discountedSubscriptions, candidate)
                    );
                },
                onSuccess: () => {
                    displayOfferApplied(discountedSubscriptions, candidate);
                },
            })
        );
    };

    const handleDiscountAppliedPopupClosed = (offer: string) => {
        closeModal();
        trackDiscountAppliedClick(offer);
    };

    const displayOfferApplied = (product: IDiscountSubscription, candidate: ISubscription) => {
        trackSuccessChangeProduct(RETURN_OFFER, getLabel());

        openModal(ModalName.universalModal, {
            image: 'rising-hands',
            trackLoad: () => trackDiscountApplied(getLabel(), candidate.contentId),
            onClose: () => handleDiscountAppliedPopupClosed(getLabel()),
            onPrimaryClick: () => handleDiscountAppliedPopupClosed(getLabel()),
            withCloseButton: true,
            captions: {
                title: t('subscription.specialOffer.offerApplied.title'),
                extraText: {
                    text: t('subscription.specialOffer.offerApplied.description', {
                        currencySign: getCurrencySign(product?.currency),
                        price: getPriceForCurrency(product),
                        period: product.subscription_period,
                    }),
                    color: 'text-main',
                },
                primaryButton: t('basics.continue'),
            },
            dataLocators: {
                modal: 'cancelSubModal',
                container: 'updatingSubscriptionPlanModal',
                primaryButton: 'updatingSubscriptionPlanContinueButton',
                closeButton: 'updatingSubscriptionPlanCloseButton',
            },
        });
    };

    const displayUpdatingProcessModal = (dataLocator = 'updatingSubscriptionPlanLoader') => {
        openModal(ModalName.waitingModal, {
            captions: {
                title: t('subscription.updatingPlan.inProgress'),
            },
            dataLocatorContainer: dataLocator,
        });
    };

    const getCommonModalParams = () => ({
        image: 'errorAttention' as const,
        withCloseButton: true,
        withContactUsLink: true,
        captions: {
            title: t('subscription.cancelling.issueModal.title'),
            primaryButton: t('basics.tryAgain'),
            tertiaryButton: t('basics.contactSupport'),
        },
        dataLocators: {
            modal: 'cancelSubModal',
            container: 'updatingSubscriptionPlanModal',
            primaryButton: 'errorModalTryAgain',
            closeButton: 'errorModalCloseButton',
        },
    });

    const displayCancellingIssue = (onRetry: () => void) => {
        openModal(ModalName.universalModal, {
            trackLoad: () => {
                trackCancelSubscriptionFailedScreenLoad();
            },
            onClose: closeModal,
            onPrimaryClick: () => {
                trackCancelSubscriptionFailedRetryClick();
                onRetry();
            },
            ...getCommonModalParams(),
        });
    };

    const displayProcessingIssue = (
        trackLoad: () => void,
        eventLabel: string,
        retryCall: (discountedSubscriptions?: IDiscountSubscription, candidate?: ISubscription) => void
    ) => {
        openModal(ModalName.universalModal, {
            trackLoad,
            onClose: closeModal,
            onPrimaryClick: () => {
                closeModal();
                trackRetryOfferClick(ACCOUNT_SUBSCRIPTION, eventLabel);
                retryCall();
            },
            ...getCommonModalParams(),
        });
    };

    const getDiscountSubscriptionInfo = (
        candidateForOffer: ISubscription,
        discountedSubscription: IDiscountSubscription
    ): IDiscountSubscriptionInfo => {
        const amount = getPriceFromCents(
            candidateForOffer?.product.amount as number,
            candidateForOffer?.product.currency
        );
        const specialOfferPrice = getPriceForCurrency(discountedSubscription as IDiscountSubscription);

        const isSpecialOfferLessExpensive =
            checkIsProductIdWithTestPrice(candidateForOffer?.product.id) || specialOfferPrice < Number(amount);

        const discount = getDiscount(Number(amount), specialOfferPrice);

        return {
            isSpecialOfferLessExpensive,
            discount,
        };
    };

    const displayReturnOffer = (candidateForReturnOffer: ISubscription, afterCancel: boolean | undefined = false) => {
        const discountedSubscriptions: IDiscountsForSubscriptions = specialOfferProductRef.current;

        if (isFromCRM && !afterCancel) {
            trackShowReturnOfferForCrmClick();
            navigate(PRIVATE.RETURN_OFFER.path);

            return;
        }

        const discountedSubscription: IDiscountSubscription | undefined = getAppropriateDiscountForSubscription(
            candidateForReturnOffer,
            discountedSubscriptions,
            DISCOUNT_TYPES.extra
        );

        if (!discountedSubscription || isEmpty(discountedSubscription)) {
            closeModal();

            return;
        }

        const { isSpecialOfferLessExpensive, discount } = getDiscountSubscriptionInfo(
            candidateForReturnOffer,
            discountedSubscription
        );

        const isCurrentSubscriptionMain = isMainSubscription(candidateForReturnOffer.product_code);

        if (isSpecialOfferLessExpensive) {
            openModal(ModalName.discountOfferModal, {
                onClose: () => {
                    trackCloseDiscountOffer(80, candidateForReturnOffer.contentId, isCaliforniaFlow);
                    closeModal();
                },
                onSubmit: () => {
                    handleSubmitReturnOffer(
                        discountedSubscription as IDiscountSubscription,
                        candidateForReturnOffer as ISubscription
                    );
                    trackRenewPlan(RETURN_OFFER, getLabel());
                },
                trackLoad: () => {
                    trackReturnOfferLoad(getLabel());
                },
                captions: {
                    headerTitle: t('subscription.specialOffer.title'),
                    additionalInfo: {
                        title: t('basics.subscription.cancelledTitle'),
                        text: t('subscription.cancellation.returnOffer.statusAdditionalInfo', {
                            minutesAmount: 15,
                        }),
                    },
                    label: t('subscription.cancellation.returnOffer.lastChanceOffer'),
                    title: 'subscription.cancellation.returnOffer.renewNow.title',
                    firstBadgeText: t('subscription.cancellation.returnOffer.firstBadge'),
                    secondBadgeText: t('subscription.cancellation.specialOffer.withDynamicPercent.secondBadge', {
                        discount: discount,
                    }),
                    extraText: t('subscription.discountOffer.disclaimerNew'),
                    submitButton: t('basics.renewSubscription'),
                    periodTitle: t('subscription.cancellation.specialOffer.namePeriodICU', {
                        period: discountedSubscription.subscription_period,
                    }),
                    period: discountedSubscription.subscription_period,
                },
                oldProduct: candidateForReturnOffer?.product as Product,
                specialOfferProduct: discountedSubscription as IDiscountSubscription,
                discount,
                isCurrentSubscriptionMain,
            });

            return;
        }
        console.warn('Return offer is not available');
        closeModal();
    };

    return {
        getDiscountSubscriptionInfo,
        displaySurveyModal,
        displayProcessingIssue,
        displayUpdatingProcessModal,
        displayOfferApplied,
        displayCancellingIssue,
        displayReturnOffer,
        handleSubmitReturnOffer,
    };
};

export default useCommonSubscriptionLogic;
