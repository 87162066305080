/* eslint-disable max-lines */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { IResultResponse } from 'api/types/response';
import api from 'api';

import {
    cancelSubscription,
    changeSubscription,
    fetchDiscountSubscriptions,
    fetchSubscriptions,
    pauseSubscription as pauseSubscriptionAction,
    restoreSubscription,
    resumeSubscription,
    returnSubscription,
    sendFeedback,
    setIsDiscountLoaded,
    setReminder as setReminderAction,
    setSubscriptions,
    setSubscriptionsFetchingStatus,
} from './actions';
import { notifyError, notifySuccess } from '../notifications/actions';
import { handleErrorAction } from '../errors/actions';

import {
    CANCEL_SUBSCRIPTION,
    CHANGE_SUBSCRIPTION,
    FETCH_DISCOUNT_SUBSCRIPTION,
    FETCH_USER_SUBSCRIPTIONS,
    PAUSE_SUBSCRIPTION,
    RESTORE_SUBSCRIPTION,
    RESUME_SUBSCRIPTION,
    RETURN_SUBSCRIPTION,
    SEND_SUBSCRIPTION_FEEDBACK,
    SET_REMINDER,
} from './actionTypes';

import { subscriptionActions } from './constants';

import getContentId from 'services/Analytics/helpers/getContentId';

import { getPriceForCurrency, getPriceFromCents } from 'helpers/prices';
import { handleSubscriptionErrorInfo, sortFullAccessFirst } from './helpers';

import {
    ICancelSubscription,
    IDiscountsForSubscriptions,
    IDiscountSubscription,
    ISubscription,
    ISubscriptionResponse,
} from 'types/subscription';

import { selectDiscountedSubscriptions } from './selectors';
import { trackCancelSubscriptionRequest } from './analytics';

function* getSubscription() {
    try {
        const response: ISubscriptionResponse[] = yield call(api.subscriptions.getSubscriptions);

        const subscriptionsWithContentId: ISubscription[] = response.map((subscription) => {
            const { amount, trial_amount } = subscription.product ?? {};

            const contentId: string = getContentId({
                product_code: subscription.product_code,
                price: getPriceFromCents(amount),
                trialPrice: getPriceFromCents(trial_amount),
                payment_type: 'subscription',
                trialPeriod: subscription.subscription_trial_period!,
                period: subscription.subscription_period,
            });

            return {
                ...subscription,
                contentId,
            };
        });

        yield put(setSubscriptions(sortFullAccessFirst(subscriptionsWithContentId)));
        yield put(setSubscriptionsFetchingStatus(false));
    } catch (error) {
        notifyError('getSubscription error');
        yield put(handleErrorAction(error));
    }
}

function* makeSubscriptionCancelling({ payload }: ReturnType<typeof cancelSubscription>) {
    try {
        yield call(callUnsubscribe, payload);
    } catch (error) {
        yield put(handleErrorAction(error, payload));
    }
}

function* sendSubscriptionFeedback({ payload }: ReturnType<typeof sendFeedback>) {
    try {
        yield call(api.subscriptions.sendFeedback, payload);
    } catch (error) {
        yield put(handleErrorAction(error, payload));
    }
}

function* callUnsubscribe(payload: ICancelSubscription) {
    try {
        trackCancelSubscriptionRequest();

        const response: IResultResponse = yield call(api.subscriptions.unsubscribe, {
            external_id: payload.externalId,
        });

        if (response.result) {
            yield put(fetchSubscriptions());
            yield put(notifySuccess('subscription.cancellation.response.success'));
            payload.onSuccess();
        }
    } catch (error) {
        handleSubscriptionErrorInfo(payload, error, subscriptionActions.cancel);

        yield put(notifyError('subscription.cancellation.response.error'));

        payload.onError();
    }
}

function* change({ payload }: ReturnType<typeof changeSubscription>) {
    const { subscription, onSuccess, onError } = payload;

    try {
        yield api.subscriptions.changeSubscription(subscription);
        yield put(notifySuccess('subscription.change.response.success'));
        yield put(fetchSubscriptions());

        onSuccess();
    } catch (error) {
        handleSubscriptionErrorInfo(payload, error, subscriptionActions.change);

        yield put(notifyError('subscription.change.response.error'));

        onError();
    }
}

function* getDiscountSubscription({ payload }: ReturnType<typeof fetchDiscountSubscriptions>) {
    try {
        yield put(setIsDiscountLoaded(false));

        const currentDiscountedSubscriptions: IDiscountsForSubscriptions = yield select(
            selectDiscountedSubscriptions
        ) || {};

        const handleDiscountSubscriptionResponse = (response: IDiscountSubscription, external_id: string) => {
            if (!response) {
                return;
            }

            const productDiscount = currentDiscountedSubscriptions[external_id] || {};

            if (!productDiscount[payload?.discount_type]) {
                productDiscount[payload?.discount_type] = { subscription: response };
            }

            currentDiscountedSubscriptions[external_id] = productDiscount;
        };

        if (payload?.subscriptionsAvailableForOffer) {
            // Make API calls sequentially for each subscription
            for (const subscription of payload?.subscriptionsAvailableForOffer) {
                const response: IDiscountSubscription = yield call(api.subscriptions.getDiscountSubscriptions, {
                    discount_type: payload?.discount_type,
                    external_id: subscription?.external_id,
                });

                handleDiscountSubscriptionResponse(response, subscription?.external_id);
            }
        } else {
            // Make a single API call
            const response: IDiscountSubscription = yield call(api.subscriptions.getDiscountSubscriptions, {
                discount_type: payload?.discount_type,
                external_id: payload?.external_id,
            });

            handleDiscountSubscriptionResponse(response, payload?.external_id);
        }

        yield put(setIsDiscountLoaded(true));

        payload?.onSuccess && payload.onSuccess();
    } catch (error) {
        handleSubscriptionErrorInfo(payload, error, subscriptionActions.discount);

        yield put(setIsDiscountLoaded(true));
        payload?.onError && payload.onError();
    }
}

function* switchSubscription({ payload }: ReturnType<typeof returnSubscription>) {
    const { product, external_id, onSuccess, onError } = payload;

    const { id, name, currency } = product;

    try {
        yield call(api.subscriptions.returnSubscription, {
            external_id,
            product: {
                id,
                name,
                currency,
                amount: product.amount,
                product_id: product.product_id,
                started_from: product.started_from,
                subscription_period: product.subscription_period,
            },
        });

        yield put(notifySuccess('subscription.change.response.success'));
        yield put(fetchSubscriptions());

        onSuccess && onSuccess();
    } catch (error) {
        handleSubscriptionErrorInfo(payload, error, subscriptionActions.restoreSwitch);

        yield put(notifyError('subscription.change.response.error'));

        onError && onError();
    }
}

function* pauseSubscription({ payload }: ReturnType<typeof pauseSubscriptionAction>) {
    const { onSuccess, onError } = payload;

    try {
        yield api.subscriptions.pauseSubscription();

        yield put(notifySuccess('subscription.change.response.success'));
        yield put(fetchSubscriptions());

        onSuccess && onSuccess();
    } catch (error) {
        handleSubscriptionErrorInfo(payload, error, subscriptionActions.pause);

        yield put(notifyError('subscription.change.response.error'));

        onError && onError();
    }
}

function* setReminder({ payload }: ReturnType<typeof setReminderAction>) {
    const { external_id, onSuccess, onError } = payload;

    try {
        yield call(api.subscriptions.setReminder, {
            external_id,
        });
        yield put(fetchSubscriptions());

        onSuccess && onSuccess();
    } catch (error) {
        handleSubscriptionErrorInfo(payload, error, subscriptionActions.reminder);

        onError && onError();
    }
}

function* restore({ payload }: ReturnType<typeof restoreSubscription>) {
    const { external_id, onSuccess, onError } = payload;

    try {
        yield api.subscriptions.restoreSubscription({ external_id });

        yield put(fetchSubscriptions());

        onSuccess && onSuccess();
        yield put(notifySuccess('subscription.change.response.success'));
    } catch (error) {
        handleSubscriptionErrorInfo(payload, error, subscriptionActions.restore);

        yield put(notifyError('subscription.change.response.error'));

        onError && onError();
    }
}

function* resume({ payload }: ReturnType<typeof resumeSubscription>) {
    const { onSuccess, onError } = payload;

    try {
        yield api.subscriptions.resumeSubscription();

        yield put(fetchSubscriptions());

        onSuccess && onSuccess();
        yield put(notifySuccess('subscription.change.response.success'));
    } catch (error) {
        handleSubscriptionErrorInfo(payload, error, subscriptionActions.resume);

        yield put(notifyError('subscription.change.response.error'));

        onError && onError();
    }
}

export default function* watchSubscriptions() {
    yield all([
        takeLatest(FETCH_USER_SUBSCRIPTIONS, getSubscription),
        takeLatest(CANCEL_SUBSCRIPTION, makeSubscriptionCancelling),
        takeLatest(SEND_SUBSCRIPTION_FEEDBACK, sendSubscriptionFeedback),
        takeLatest(CHANGE_SUBSCRIPTION, change),
        takeLatest(RESTORE_SUBSCRIPTION, restore),
        takeLatest(RETURN_SUBSCRIPTION, switchSubscription),
        takeLatest(FETCH_DISCOUNT_SUBSCRIPTION, getDiscountSubscription),
        takeLatest(PAUSE_SUBSCRIPTION, pauseSubscription),
        takeLatest(SET_REMINDER, setReminder),
        takeLatest(RESUME_SUBSCRIPTION, resume),
    ]);
}
