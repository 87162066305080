import { EventDataTrack } from 'wikr-core-analytics/dist/Interfaces/Events';
import { ITrackNew, Main, Provider, systemNames } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_ANALYTIC } from 'sentry-utils';

import config from 'config';

import sentry from 'services/Sentry/SentryInstance';

import { CustomDataOptions, ITrackOptions, TrackOptionsScreenLoad } from './types';

export const initAnalytics = (awsId: string | undefined | null) => {
    Main.initSystems({
        activeSystems: [
            {
                name: systemNames.ga4,
                id: config.GOOGLE_4_ID,
            },
            {
                name: systemNames.ga,
                id: config.GOOGLE_ID,
            },
            {
                name: systemNames.amazon,
                id: config.AMAZON_ID,
                config: {
                    releaseDate: config.RELEASE_DATE,
                    streamName: config.AWS_DELIVERY_STREAM_NAME,
                    envMode: config.ENV,
                    awsId,
                },
            },
        ],
    });
};

const clientHandlerFn = (e: Error | unknown) => sentry.logError(e as Error, SENTRY_ANALYTIC, ERROR_LEVELS.ERROR);

export const setUserDataToAnalytics = ({
    country,
    id,
    gender,
    abTestName,
    growthBookTestName,
    region,
}: {
    id: number;
    country: string;
    gender: string;
    abTestName: string;
    growthBookTestName?: string;
    region: string | null;
}) => {
    Provider.provideData({
        gender,
        userId: id,
        country: async () => country,
        abTestName: async () => abTestName,
        clientHandlerFn,
        growthBookTestName,
        region,
    });
};

export const clearUserDataFromAnalytics = () => {
    Provider.provideData({
        userId: undefined,
        gender: '',
        country: async () => null,
        abTestName: async () => null,
        clientHandlerFn,
        growthBookTestName: '',
        region: null,
    });
};

// old version, new one -> trackNewScreenLoad
export const trackScreenLoad = (eventId: string, options?: TrackOptionsScreenLoad) => {
    Main.track(eventId, options);
};

const customTrackDecorator = (eventType: string, options: ITrackOptions) => {
    const trackParams: ITrackNew = {
        eventData: eventType,
        actionData: options?.event_action,
    };

    const data: EventDataTrack = {};

    if (options?.event_label) {
        data.event_label = options.event_label;
    }

    if (options?.contentId) {
        data.content_id = options.contentId;
    }

    trackParams.data = data;

    Main.trackNew(trackParams);
};

export const trackClick = (eventId: string, options: CustomDataOptions = {}) => {
    customTrackDecorator(`${eventId}__click`, options);
};

export const trackEvent = (eventId: string, options: CustomDataOptions = {}) => {
    customTrackDecorator(eventId, options);
};

export const trackNewScreenLoad = (
    eventId: string,
    { event_label, event_action, contentId }: CustomDataOptions = {}
) => {
    customTrackDecorator(`${eventId}__screen__load`, {
        event_action: event_action ? event_action : 'screen__load',
        event_label: event_label,
        contentId: contentId,
    });
};
